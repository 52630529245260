.med-types {
  margin: 0 0 30px 0;

  a {
    display: inline-block;
  }
}

.med-types-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.med-types-list-0 {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.med-types-list-0-item {
  margin: 0 0 5px 0;

  & > a {
    color: #71a3aa;
    font-size: 16px;
    font-weight: bold;
  }
  
  &.active > a {
    text-decoration: none;
    color: #586268;
    margin: 0 0 5px 0;
  }
}

.med-types-list-1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.med-types-list-1-item {
  margin: 0 0 3px 0;
  
  & > a {
    color: #595959;
    padding: 1px 13px 1px 13px;
    background: url('../img/misc/menu-bullet.png') no-repeat 1px 8px;
    -moz-border-radius: 3px 3px 3px 3px;
    -webkit-border-radius: 3px 3px 3px 3px;
    -o-border-radius: 3px 3px 3px 3px;
    -ms-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
  }
  
  &.active > a {
    text-decoration: none;
    color: #fff;
    background: #6dcfc0;
  }
}










