.aids-2 {
  margin: 0 0 15px 0;
}

.aids-2-list {
  list-style-type: none;
  padding: 8px 10px 10px 10px;
  margin: 0 0 30px 0;
  background: -moz-linear-gradient(top, #efeded, #f5f3f3);
  background: -webkit-linear-gradient(top, #efeded, #f5f3f3);
  background: -o-linear-gradient(top, #efeded, #f5f3f3);
  background: -ms-linear-gradient(top, #efeded, #f5f3f3);
  background: linear-gradient(top, #efeded, #f5f3f3);
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 0 2px 0 #ddd;
  -webkit-box-shadow: 0 0 2px 0 #ddd;
  -o-box-shadow: 0 0 2px 0 #ddd;
  -ms-box-shadow: 0 0 2px 0 #ddd;
  box-shadow: 0 0 2px 0 #ddd;
}

.aids-2-list-item {
  margin: 0 0 8px 0;
  padding: 10px 0 10px 10px;
  background: #fff;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 2px 4px -2px #aaa;
  -webkit-box-shadow: 0 2px 4px -2px #aaa;
  -o-box-shadow: 0 2px 4px -2px #aaa;
  -ms-box-shadow: 0 2px 4px -2px #aaa;
  box-shadow: 0 2px 4px -2px #aaa;
}

.aids-2-list-item-image {
  width: 100px;
  float: left;
}

.aids-2-list-item-info {
  margin: 0 0 0 115px;
}

.aids-2-list-item-info-title {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.aids-2-list-item-info-parameters {
  dl {
    font-size: 12px;
    margin: 0;
    
    dt {
      color: #9f9f9f;
      float: left;
      font-weight: normal;
      margin: 0 5px 0 0;
    }
    
    dd {
      height: 20px;
      
      a {
        color: #595959;
      }
    }
  }
}












