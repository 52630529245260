.responses {

}

.responses-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px 0;
  
  span {
    color: #abadae;
  }
}

.responses-form {
  padding: 8px 10px 10px 10px;
  margin: 0 0 30px 0;
  background: -moz-linear-gradient(top, #efeded, #f5f3f3);
  background: -webkit-linear-gradient(top, #efeded, #f5f3f3);
  background: -o-linear-gradient(top, #efeded, #f5f3f3);
  background: -ms-linear-gradient(top, #efeded, #f5f3f3);
  background: linear-gradient(top, #efeded, #f5f3f3);
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 0 2px 0 #ddd;
  -webkit-box-shadow: 0 0 2px 0 #ddd;
  -o-box-shadow: 0 0 2px 0 #ddd;
  -ms-box-shadow: 0 0 2px 0 #ddd;
  box-shadow: 0 0 2px 0 #ddd;
  
  fieldset {
    background: #fff;
    padding: 10px 0 15px 15px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -ms-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    -moz-box-shadow: 0 0 2px 0 #ccc;
    -webkit-box-shadow: 0 0 2px 0 #ccc;
    -o-box-shadow: 0 0 2px 0 #ccc;
    -ms-box-shadow: 0 0 2px 0 #ccc;
    box-shadow: 0 0 2px 0 #ccc;
  }
  
  dl {
    margin: 0 0 25px 0;
  
    dt {
      color: #9f9f9f;
      width: 55px;
      float: left;
      font-weight: normal;
    }
    
    dd {
      height: 22px;
      color: #595959;
    }
  }
}

.responses-form-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.responses-form-field {
  margin: 0 0 10px 0;
}

.responses-form-label {
  font-size: 12px;
  
  label {
    font-weight: normal;
  }
}

.responses-form-input {
  input {
    width: 300px;
  }
}

.responses-form-textarea {
  textarea {
    width: 625px;
    height: 100px;
  }
}

.responses-form-message {
  .responses-form-label {
    clear: both;
  }
}

.responses-form-field-name {
  float: left;
}

.responses-form-field-email {
  margin: 0 0 0 324px;
  height: 65px;
}

.responses-form-buttons {
  margin: 5px 0 0 0;
  padding: 0 20px 0 0;
  text-align: right;

  .submit {
    border: 0;
    width: 131px;
    height: 33px;
    background: url('../img/buttons/response.png');
  }
}













