.consultants-list {
	list-style-type: none;
	margin: 0 0 50px 0;
	padding: 0;
}

.consultants-list-item {
	float: left;
	width: 330px;
	margin: 0 20px 15px 0;
	padding: 9px 9px 9px 9px;
	background: -moz-linear-gradient(top, #f1efef, #f3f1f1);
	background: -webkit-linear-gradient(top, #f1efef, #f3f1f1);
	background: -o-linear-gradient(top, #f1efef, #f3f1f1);
	background: -ms-linear-gradient(top, #f1efef, #f3f1f1);
	background: linear-gradient(top, #f1efef, #f3f1f1);
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	
	&:nth-child(2n) {
		margin-right: 0;
	}
}

.consultants-list-item-wrap {
	background: #fff;
	padding: 10px 10px 10px 10px;
	height: 250px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.5);
	-o-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 3px -1px rgba(0, 0, 0, 0.5);
}

.consultants-list-item-image {
	width: 103px;
	float: left;
}

.consultants-list-item-info {
	margin: 0 0 0 118px;
	min-height: 130px;
	line-height: 1.3em;
}

.consultants-list-item-info-title {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 5px 0;
}

.consultants-list-item-info-status {
	font-size: 12px;
	color: #b3b3b3;
	margin: 0 0 10px 0;
}

.consultants-list-item-info-button {
	a {
		display: inline-block;
		width: 125px;
		height: 33px;
		background: url('../img/buttons/ask-question.png') no-repeat;
		opacity: 1;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.consultants-list-item-text {
	padding: 0 5px 0 5px;
}

.consultants-list-banner {
	margin: 30px 0 20px 0;
}