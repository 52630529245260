.apothecas-addresses {
	margin: 0 0 30px 0;
}

.apothecas-addresses-title {
	font-size: 20px;
	font-weight: bold;
	
	span {
		color: #6ed0c1;
	}
}


.apothecas-addresses-list {
	width: 680px;
}

.apothecas-addresses-list-header {

}

.apothecas-addresses-list-th {
	height: 33px;
	vertical-align: middle;
	text-align: left;
	background: #8fc7d7;
	font-size: 14px;
	color: #fff;
	font-weight: bold;
	padding: 0 0 0 15px;
	border-right: 1px solid #b2dae6;
	
	a {
		color: #fff;
		display: inline-block;
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
		
		&.sort-down {
			padding: 0 20px 0 0;
			background: url('../img/misc/bullet-down-white.png') no-repeat 100% 50%;
		}
		
		&.sort-up {
			padding: 0 20px 0 0;
			background: url('../img/misc/bullet-up-white.png') no-repeat 100% 50%;
		}
	}
}

.apothecas-addresses-list-th:first-child {
	-moz-border-radius: 4px 0 0 4px;
	-webkit-border-radius: 4px 0 0 4px;
	-o-border-radius: 4px 0 0 4px;
	-ms-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.apothecas-addresses-list-th:last-child {
	border-right: 0;
	-moz-border-radius: 0 4px 4px 0;
	-webkit-border-radius: 0 4px 4px 0;
	-o-border-radius: 0 4px 4px 0;
	-ms-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.apothecas-addresses-list-th-1 {
	width: 130px;
}


.apothecas-addresses-list-th-2 {
	width: 290px;
}


.apothecas-addresses-list-item {
	height: 80px;
	border-bottom: 1px solid #e6e6e1;
}

.apothecas-addresses-list-item-name {
	vertical-align: top;
	font-weight: bold;
	padding: 10px 0 0 0;
}

.apothecas-addresses-list-item-name-field {
	margin: 0 0 3px 0;

	input {
		display: none;
	}
	
	a {
		display: block;
		margin: 0 0 0 28px;
	}
	
	label {
		float: left;
		width: 21px;
		height: 21px;
		margin: 0 8px 0 0;
		vertical-align: middle;
		cursor: pointer;
		background: url('../img/misc/checkbox.png') no-repeat;
		
		&.active {
			background: url('../img/misc/checkbox-a.png') no-repeat;
		}
	}
}

.apothecas-addresses-list-item-name-time {
	margin: 0 0 0 30px;
}

.apothecas-addresses-list-item-image-subline {
	margin: 0 0 0 5px;
}

.apothecas-addresses-list-item-contacts {
	vertical-align: top;
	padding: 10px 0 0 15px;
}

.apothecas-addresses-list-item-contacts-site {
	margin: 0 0 2px 0;
}

.apothecas-addresses-list-item-contacts-address {
	margin: 0 0 2px 0;
}

.apothecas-addresses-list-item-contacts-phones {
	font-weight: bold;
}

.apothecas-addresses-list-item-distance {
	width: 70px;
	text-align: center;
}

.apothecas-addresses-list-item-amount {
	font-weight: bold;
	font-size: 12px;
	vertical-align: middle;
	text-align: center;
	width: 85px;
	
	.green {
		color: #59b893;
	}
	
	.red {
		color: #fe7550;
	}
}

.apothecas-addresses-list-item-price {
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	width: 85px;
	
	span {
		font-size: 18px;
	}
}

.apothecas-addresses-list-item-buttons {
	vertical-align: middle;
	text-align: center;
	text-align: right;
}

.apothecas-addresses-list-item-buttons-buy {

	a {
		display: inline-block;
		width: 72px;
		height: 33px;
		background: url('../img/buttons/buy.png') no-repeat;
		opacity: 1;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.apothecas-addresses-list-item-buttons-preorder {
	font-size: 12px;
}

















