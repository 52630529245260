.object-info {
  margin: 0 0 60px 0;
}

.object-info-tabs {
  list-style-type: none;
  margin: 0 0 15px 0;
  padding: 0;
}

.object-info-tab {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  
  a {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    padding: 4px 15px 6px 15px;
    text-decoration: none;
    cursor: pointer;
    -moz-border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    -o-border-radius: 2px 2px 2px 2px;
    -ms-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    
    span {
      border-bottom: 1px dotted #626c71;
    }
    
    &:hover {
      span {
        border-bottom: 0;
      }
    }
  }
  
  &.active {
  
    a {
      background: #73b3d2;
      color: #fff;
      
      span {
        border-bottom: 0;
      }
      
      &:before {
        content: ' ';
        position: absolute;
        left: 45%;
        bottom: -8px;
        width: 17px;
        height: 8px;
        background: url('../img/misc/tooltip-bullet-1.png') no-repeat;
      }
    }
  }
}

.object-info-layers {

}

.object-info-layer {
  display: none;
  
  .shown {
    display: block;
  }
}