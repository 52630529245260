.your-question {
	margin: 0 0 25px 0;
}

.your-question-title {
	margin: 0 0 10px 0;
	font-size: 20px;
}

.your-question-list {
	border: 1px dashed #c9d7d8;
	padding: 20px 20px 0 20px;
	list-style-type: none;
	margin: 0;
	-moz-border-radius: 8px 8px 8px 8px;
	-webkit-border-radius: 8px 8px 8px 8px;
	-o-border-radius: 8px 8px 8px 8px;
	-ms-border-radius: 8px 8px 8px 8px;
	border-radius: 8px 8px 8px 8px;
}

.your-question-list-item {
	margin: 0 0 20px 0;
}

.your-question-list-item-question {

	.your-question-list-item-text {
		font-style: italic;
	}
}

.your-question-list-item-date {
	font-size: 12px;
	color: #b9bcbd;
	margin: 0 0 2px 0;
	
	.date {
		margin: 0 12px 0 0;
		background: url('../img/consult-date-separator.png') no-repeat 100% 50%;
	}
	
	.name {
		margin: 0 0 0 12px;
	}
}

.your-question-list-item-name {
	font-weight: bold;
	font-size: 14px;
	
	img {
		margin: 0 8px 0 0;
		text-align: center;
	}
}


.your-question-list-item-answer {
	.your-question-list-item-name {
		margin: 0 0 6px 0;
	}

	.your-question-list-item-text {
		position: relative;
		padding: 18px 18px 18px 18px;
		background: #e1efec;
		-moz-border-radius: 5px 5px 5px 5px;
		-webkit-border-radius: 5px 5px 5px 5px;
		-o-border-radius: 5px 5px 5px 5px;
		-ms-border-radius: 5px 5px 5px 5px;
		border-radius: 5px 5px 5px 5px;
		
		&:before {
			content: ' ';
			width: 15px;
			height: 6px;
			position: absolute;
			left: 60px;
			top: -6px;
			background: url('../img/misc/text-bubble-top.png') no-repeat;
		}
	}
}








