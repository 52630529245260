.search-list {
  list-style-type: none;
  padding: 8px 10px 4px 10px;
  margin: 0 0 30px 0;
  background: -moz-linear-gradient(top, #efeded, #f5f3f3);
  background: -webkit-linear-gradient(top, #efeded, #f5f3f3);
  background: -o-linear-gradient(top, #efeded, #f5f3f3);
  background: -ms-linear-gradient(top, #efeded, #f5f3f3);
  background: linear-gradient(top, #efeded, #f5f3f3);
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 0 2px 0 #ddd;
  -webkit-box-shadow: 0 0 2px 0 #ddd;
  -o-box-shadow: 0 0 2px 0 #ddd;
  -ms-box-shadow: 0 0 2px 0 #ddd;
  box-shadow: 0 0 2px 0 #ddd;
}

.search-list-item {
  background: #fff;
  margin: 0 0 10px 0;
  border: 1px solid #e7e7e7;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 10px 5px -5px #ccc;
  -webkit-box-shadow: 0 10px 5px -5px #ccc;
  -o-box-shadow: 0 10px 5px -5px #ccc;
  -ms-box-shadow: 0 10px 5px -5px #ccc;
  box-shadow: 0 10px 5px -5px #ccc;
}

.search-list-item-form {  
  fieldset {
    padding: 5px 0 15px 11px;
    min-height: 95px;
    position: relative;
  }
}

.search-list-item-image {
  margin: 0 0 5px 0;
}

.search-list-item-count {
  position: absolute;
  right: 10px;
  top: 15px;
  padding: 0 0 0 20px;
  height: 12px;
  font-size: 10px;
  background: url('../img/misc/stats.png') no-repeat;
}

.search-list-item-field {
  position: absolute;
  bottom: 15px;

  input[type="text"] {
    width: 185px;
    margin: 0 5px 0 0;
  }
  
  input[type="submit"] {
    width: 32px;
    height: 33px;
    border: 0;
    background: url('../img/buttons/search.png') no-repeat;
  }
}





