.med-search {
	padding: 10px 11px 11px 11px;
	margin: 0 0 50px 0;
	position: relative;
	background: -moz-linear-gradient(left, #7dacb3, #6ac9bb);
	background: -webkit-linear-gradient(left, #7dacb3, #6ac9bb);
	background: -o-linear-gradient(left, #7dacb3, #6ac9bb);
	background: -ms-linear-gradient(left, #7dacb3, #6ac9bb);
	background: linear-gradient(left, #7dacb3, #6ac9bb);
	-moz-border-radius: 4px 4px 4px 4px;
	-webkit-border-radius: 4px 4px 4px 4px;
	-o-border-radius: 4px 4px 4px 4px;
	-ms-border-radius: 4px 4px 4px 4px;
	border-radius: 4px 4px 4px 4px;
}

.med-search-form {
	background: #fff;
	padding: 15px 0 15px 17px;
	-moz-border-radius: 4px 4px 4px 4px;
	-webkit-border-radius: 4px 4px 4px 4px;
	-o-border-radius: 4px 4px 4px 4px;
	-ms-border-radius: 4px 4px 4px 4px;
	border-radius: 4px 4px 4px 4px;

	fieldset {
		padding: 0 0 25px 0;
		margin: 0 18px 0 0;
		border-bottom: 1px solid #e6e6e1;
		
		&:last-child {
			border: 0;
			padding: 0;
		}
	}
	
	legend {
		margin: 15px 0 10px 0;
		display: block;
		border: 0;
		font-size: 14px;
		font-weight: bold;
		color: #586268;
	}
}


.med-search-form-title {
	font-size: 16px;
	font-weight: bold;
	margin: 0 0 10px 0;
	border: 0;
	color: #586268;
}

.med-search-form-field {
	
	input {
		padding: 0 0 0 10px !important;
	}
}

.med-search-form-field-label {
	margin: 0 0 4px 0;

	font-size: 12px;
}

.med-search-form-field-city {
	width: 160px;
	float: left;

	.drop-down {
		width: 160px;
		
		input {
			width: 160px;
		}
	}
	
	.drop-down-list {
		display: none;
		background: #fff;
		border: 1px solid #ddd;
		padding: 5px 5px 5px 5px;
		position: absolute;
		top: 35px;
		left: 0;
		width: 100%;
		z-index: 10;
		-moz-border-radius: 4px 4px 4px 4px;
		-webkit-border-radius: 4px 4px 4px 4px;
		-o-border-radius: 4px 4px 4px 4px;
		-ms-border-radius: 4px 4px 4px 4px;
		border-radius: 4px 4px 4px 4px;
		
		&.shown {
			display: block;
		}
		
		.drop-down-list-item {
			display: block;
		}
	}
}

.med-search-form-checkboxes {
	input {
		display: none;
	}
	
	label {
		cursor: pointer;
		font-weight: normal !important;
		font-size: 13px;
		display: inline-block;
		height: 21px;
		padding: 0 0 0 28px;
		white-space: nowrap;
		background: url('../img/misc/checkbox.png') no-repeat;
		
		&.active {
			background: url('../img/misc/checkbox-a.png') no-repeat;
		}
	}
}

.med-search-form-checkboxes-type {

}

.med-search-form-checkboxes-type-top {
	margin: 0 0 5px 0;
	
	label {
		width: 120px;
		padding-top: 1px;
	}
}

.med-search-form-checkboxes-type-bottom {
	label {
		padding-top: 1px;
	}
}

.med-search-form-checkboxes-type-row-blue {
	background: #eaf5f3;
	padding: 8px 20px 6px 10px;
	margin: 0 8px 0 0;
	display: inline-block;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-ms-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.med-search-form-checkboxes-type-row-red {
	background: #fff3e9;
	display: inline-block;
	padding: 8px 20px 6px 10px;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-ms-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.type-checkbox-5 { 
	margin: 0 40px 0 0;
}

.med-search-form-checkboxes-service {
	label {
		width: 205px;
		padding-top: 1px;
	}
}

.med-search-form-checkboxes-service-item {
	display: inline-block;
	width: 205px;
	margin: 0 0 8px 0;
	vertical-align: top;
}

.med-search-form-services-all-link {
	text-align: right;
	font-size: 12px;
}

.med-search-form-keywords {
	input[type="text"] {
		height: 33px;
		width: 539px;
		margin: 0 10px 0 0;
		padding: 0 0 0 10px;
	}
	
	input[type="submit"] {
		border: 0;
		opacity: 1;
		transition: opacity 0.3s;
		width: 72px;
		height: 33px;
		background: url('../img/buttons/find.png') no-repeat;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.med-search-form-field-city-choose-on-map {
	text-align: right;
	font-size: 12px;
	margin: 5px 0 0 0;

	img {
		margin: 0 5px 0 0;
	}
}


.med-search-form-field-district {
	float: left;
	margin: 0 0 0 15px;
	width: 160px;
	
	.drop-down {
		width: 160px;
		
		input {
			width: 160px;
		}
	}
	
	.drop-down-list {
		display: none;
		background: #fff;
		border: 1px solid #ddd;
		padding: 5px 5px 5px 5px;
		position: absolute;
		top: 35px;
		left: 0;
		width: 100%;
		z-index: 10;
		-moz-border-radius: 4px 4px 4px 4px;
		-webkit-border-radius: 4px 4px 4px 4px;
		-o-border-radius: 4px 4px 4px 4px;
		-ms-border-radius: 4px 4px 4px 4px;
		border-radius: 4px 4px 4px 4px;
		
		&.shown {
			display: block;
		}
		
		.drop-down-list-item {
			display: block;
		}
	}
}

.med-search-form-field-location {
	float: left;
	margin: 0 0 0 15px;
	width: 270px;
	
	input {
		width: 270px;
	}
}

.med-search-form-field-location-choose-on-map {
	text-align: right;
	font-size: 12px;
	margin: 5px 0 0 0;

	img {
		margin: 0 5px 0 0;
	}
}

.med-search-form-bottom {
	margin: 15px 0 0 0;
}

.med-search-form-preorder {
	input {
		display: none;
	}
	
	label {
		cursor: pointer;
		display: inline-block;
		height: 21px;
		padding: 0 0 0 28px;
		line-height: 20px;
		color: #586268;
		background: url('../img/misc/checkbox.png') no-repeat;
		
		&.active {
			background: url('../img/misc/checkbox-a.png') no-repeat;
		}
	}
}



.med-search-form-buttons {
	float: right;
	padding: 0 17px 0 0;

	input {
		border: 0;
		background: url('../img/buttons/apply.png') no-repeat;
		opacity: 1;
		transition: opacity 0.3s;
		width: 104px;
		height: 33px;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.med-search-view-modes {
	text-align: center;
	position: absolute;
	width: 100%;
	bottom: -32px;
	left: 0;
}

.med-search-view-modes-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: inline-block;
	height: 32px;
	background: #7dc0bf;
	font-size: 12px;
	padding: 0 11px 0 11px;
	-moz-border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
	-o-border-radius: 0 0 5px 5px;
	-ms-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
}

.med-search-view-modes-list-item {
	display: inline-block;
	
	a {
		display: inline-block;
		background-color: #fff;
		height: 22px;
		padding: 2px 11px 0 25px;
		text-decoration: none;
		font-size: 12px;
		font-weight: bold;
		color: #636c72;
		outline: none;
		-moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		-o-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
		-moz-border-radius: 2px 2px 2px 2px;
		-webkit-border-radius: 2px 2px 2px 2px;
		-o-border-radius: 2px 2px 2px 2px;
		-ms-border-radius: 2px 2px 2px 2px;
		border-radius: 2px 2px 2px 2px;
	}
	
	&.active {
		a {
			background-color: #72b5b3;
			color: #fff;
			-moz-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
			-o-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
			-ms-box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
			box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
		}
	}
}


.med-search-view-modes-list-item-list {
	a {
		background-image: url('../img/icons/view-list.png');
		background-repeat: no-repeat;
		background-position: 7px 6px;
		background-repeat: no-repeat;
		margin: 0 8px 0 0;
	}
}

.med-search-view-modes-list-item-on-map {
	a {
		background-image: url('../img/icons/view-on-map.png');
		background-repeat: no-repeat;
		background-position: 9px 3px;
		background-repeat: no-repeat;
	}
}



































