.consultants {
	margin: 0 0 50px 0;
}

.consultants-title {
	margin: 0;
	padding: 0;
	margin: 0 0 10px 0;
	font-size: 20px;
	font-weight: bold;
}

.consultants-search {
	margin: 0 0 20px 0;
}

.consultants-search-label {
	font-size: 12px;
	margin: 0 0 4px 0;
}

.consultants-search-field {
	width: 583px;
	margin: 0 7px 0 0;
	float: left;
	
	input[type="text"] {
		height: 33px;
		width: 583px;
		position: relative;
		cursor: pointer;
		padding: 0 0 0 15px;
		opacity: 1;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
		
		&:after {
			content: ' ';
			position: absolute;
			z-index: 10;
			right: 12px;
			top: 6px;
			width: 12px;
			height: 8px;
			background: url('../img/misc/drop-down-bullet.png') no-repeat;
		}
	}
	
	.drop-down {
		position: relative;
	}
	
	.drop-down-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: absolute;
		left: 0;
		top: 35px;
		width: 583px;
		border: 1px solid #ddd;
		border-radius: 4px 4px 4px 4px;
		display: none;
		background: #fff;
		
		&.shown {
			display: block !important;
		}
	}
	
	.drop-down-list-item {
		display: block;
		padding: 3px 0 3px 15px
	}
}

.consultants-search-submit {
	float: left;
	
	input {
		border: 0;
		width: 89px;
		height: 33px;
		background: url('../img/buttons/show.png') no-repeat;
	}
}






.consultants-list-banner {
	img {
		width: 100%;
	}
}







