.main-consult {
	.content-3-cols-title {
		height: 48px;
		padding: 10px 0 0 60px;
		background: url('../img/icons/main-consult.png') no-repeat;
	}
}

.main-consult-list {
	list-style-type: none;
	margin: 0 0 10px 0;
	padding: 0;
}

.main-consult-list-item {
	margin: 0 0 15px 0;
	padding: 0 0 15px 0;
	border-bottom: 1px solid #efefed;
}


.main-consult-list-item:last-child {
	border-bottom: 0;
}

.main-consult-list-item-date-name {
	margin: 0 0 5px 0;
	font-size: 12px;
	color: #ccc;
	
	.date {
		display: inline-block;
		padding: 0 11px 0 0;
		margin: 0 10px 0 0;
		background: url('../img/consult-date-separator.png') no-repeat 100% 0;
	}
}

.main-consult-list-item-text {
	font-style: italic;
	margin: 0 0 5px 0;
}

.main-consult-list-item-category {
	font-size: 12px;
	
	a {
		color: #5f6064;
	}
}

.main-consult-ask {
	margin: 0 0 25px 0;
}

.main-consult-all {
	height: 38px;
	padding: 0 0 0 47px;
	font-size: 12px;
	line-height: 35px;
	background: url('../img/icons/show-all-gray.png') no-repeat;
	
	&:hover {
		background: url('../img/icons/show-all-blue.png') no-repeat;
	}
	
	a {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}






