.aids {

}

.aids-list {
 list-style-type: none;
 margin: 0;
 padding: 0;
}

.aids-list-item {
  width: 225px;
  float: left;
  margin: 0 0 40px 0;
}

.aids-list-item-image {
  margin: 0 0 15px 0;
}

.aids-list-item-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px 0;
}

.aids-list-item-types {
  list-style-type: none;
  margin: 0;
  padding: 0 30px 0 0;
}

.aids-list-item-types-item {
  margin: 0 0 5px 0;
  
  a {
    color: #595959;
  }
}

.aids-list-item-banner {
  margin: 0 0 40px 0;
}





