.location-map {
	position: relative;
}

.location-map-title {
	font-size: 20px;
	font-weight: bold;
	color: #586268;
	margin: 0 0 16px 0;
}

.map-bubble {
	background: #fff; color: #3b4144; width: 220px; height: 200px; padding: 15px 15px 15px 15px; font-size: 13px; position: absolute; z-index: 50; right: 85px; top: 90px;
	-moz-border-radius: 4px 4px 4px 4px;
	-webkit-border-radius: 4px 4px 4px 4px;
	-o-border-radius: 4px 4px 4px 4px;
	-ms-border-radius: 4px 4px 4px 4px;
	border-radius: 4px 4px 4px 4px;
	-moz-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
	-o-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
	-ms-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
	
	&:after {
		content: ' ';
		position: absolute; 
		width: 9px;
		height: 6px;
		bottom: -6px;
		left: 50%;
		background: url('../img/misc/bullet-down-white.png') no-repeat 0 0;
	}
}

.map-bubble-title {
	margin: 0 0 12px 0;
	font-weight: bold;

	a {
		color: #6bb1bb;
		
		&:hover {
			text-decoration: none;
		}
	}
}

.map-bubble-address {

}

.map-bubble-phone {
	font-weight: bold;
	margin: 0 0 15px 0;
}

.map-bubble-worktime {
	padding: 0 0 0 30px;
	font-size: 12px;
	margin: 0 0 12px 0;
	background: url('../img/icons/time-green.png') no-repeat 0 50%;
}

.map-bubble-worktime-line {
	
	span {
		color: #b9bcbd;
		display: inline-block;
		width: 70px;
	}
}

.map-bubble-distance {
	font-size: 12px;
	margin: 0 0 7px 0;
	
	span {
		color: #b9bcbd;
	}
}

.map-bubble-choose {
	
	input {
		display: none;
	}
	
	label {
		display: inline-block;
		height: 21px;
		cursor: pointer;
		font-size: 12px;
		font-weight: normal;
		line-height: 18px;
		padding: 0 0 0 29px;
		background: url('../img/misc/checkbox.png') no-repeat 0 0;
	
		&.active {
			background: url('../img/misc/checkbox-a.png') no-repeat 0 0;
		}
	}
}





















