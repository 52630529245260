.object {
  position: relative;
}

.object-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px 0;
  
  img {
    vertical-align: middle;
    margin: 0 10px 0 0;
  }
}

.object-count {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 0 0 0 20px;
  font-size: 10px;
  color: #a0a3a5;
  background: url('../img/misc/stats.png') no-repeat 0 0;
}

.object-card {
  padding: 8px 10px 10px 10px;
  margin: 0 0 30px 0;
  background: -moz-linear-gradient(top, #efeded, #f5f3f3);
  background: -webkit-linear-gradient(top, #efeded, #f5f3f3);
  background: -o-linear-gradient(top, #efeded, #f5f3f3);
  background: -ms-linear-gradient(top, #efeded, #f5f3f3);
  background: linear-gradient(top, #efeded, #f5f3f3);
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 0 2px 0 #ddd;
  -webkit-box-shadow: 0 0 2px 0 #ddd;
  -o-box-shadow: 0 0 2px 0 #ddd;
  -ms-box-shadow: 0 0 2px 0 #ddd;
  box-shadow: 0 0 2px 0 #ddd;
  
  dl {
    margin: 0 0 25px 0;
  
    dt {
      color: #9f9f9f;
      width: 55px;
      float: left;
      font-weight: normal;
    }
    
    dd {
      height: 22px;
      color: #595959;
    }
  }
}

.object-card-wrap {
  background: #fff;
  padding: 10px 0 15px 15px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 0 2px 0 #ccc;
  -webkit-box-shadow: 0 0 2px 0 #ccc;
  -o-box-shadow: 0 0 2px 0 #ccc;
  -ms-box-shadow: 0 0 2px 0 #ccc;
  box-shadow: 0 0 2px 0 #ccc;
}

.object-card-title {
  color: #586268;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.object-card-contacts {
  float: left;
  width: 310px;
}

.object-card-other {
  padding: 10px 25px 10px 30px;
  border: 1px dashed #bccdce;
  display: inline-block;
  background: url('../img/misc/map-marker.png') no-repeat 17px 50%;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

.object-card-map {
  width: 320px;
  height: 230px;
  margin: 0 0 15px 310px;
}

.object-card-worktime {
  width: 310px;
  float: left;
}

.object-card-route {
  margin: 0 0 0 310px;
}








