@import "components/components.less";

.site-middler {
  margin: 0 0 50px 0;
}

.breadcrumbs {
  color: #b9bcbd;
  font-size: 12px;
  margin: 10px auto 0 auto;
  width: 980px;
  height: 25px;
  
  a {
    color: #b9bcbd;
  }
}

.location-map {
	margin: 20px 0 25px 0;
}

.site-2-cols {
  display: table;
  behavior: url('/js/display-table.min.htc'); 
  -dt-display: table;
  width: 980px;
  margin: 0 auto 0 auto;
}

.site-2-cols-col-1 {
  display: table-cell;
  -dt-display: table-cell;
  width: 715px;
  padding: 0 35px 0 0;
  vertical-align: top;
}
.site-2-cols-col-2 {
  display: table-cell;
  -dt-display: table-cell;
  width: 265px;
  position: relative;
  top: -25px;
  vertical-align: top;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.content-3-cols {
	display: table;
	behavior: url('/js/display-table.min.htc');
	-dt-display: table;
	width: 980px;
	margin: 0 auto 50px auto;
}

.content-3-cols-col {
	display: table-cell;
	-dt-display: table-cell;
	width: 33.3%;
}

.content-3-cols-col-1 {
	padding: 0 43px 0 0;
	width: 36%;
}

.content-3-cols-col-2 {
	padding: 0 43px 0 0;
}

.content-3-cols-title {
	font-size: 18px;
	font-weight: bold;
	margin: 0 0 20px 0;
}

.content-2-cols {
	display: table;
	behavior: url('/js/display-table.min.htc');
	-dt-display: table;
	width: 980px;
	margin: 0 auto 25px auto;
}

.content-2-cols-col {
	display: table-cell;
	-dt-display: table-cell;
	width: 50%;
}

.content-2-cols-col-1 {
	padding: 0 40px 0 0;
	border-right: 1px solid #efefed;
}

.content-2-cols-col-2 {
	padding: 0 0 0 40px;
}

.content-banner {
	margin: 0 0 25px 0;
}


