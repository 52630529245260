.product {
	background: #f5f3f3;
	padding: 10px 11px 10px 11px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.product-wrap {
	background: #fff;
	padding: 10px 10px 10px 10px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.product-left-col {
	float: left;
	width: 260px;
}

.product-right-col {
	margin: 0 0 0 265px;
}

.product-image {
	width: 235px;
	height: 235px;
	margin: 0 0 20px 0;
	background: #f5f3f3;
	text-align: center;
	padding: 10px 0 0 0;
	-moz-border-radius: 4px 4px 4px 4px;
	-webkit-border-radius: 4px 4px 4px 4px;
	-o-border-radius: 4px 4px 4px 4px;
	-ms-border-radius: 4px 4px 4px 4px;
	border-radius: 4px 4px 4px 4px;
	-moz-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.product-relatives {

}

.product-relatives-title {
	font-size: 18px;
	font-weight: bold;
	margin: 0 0 10px 0;
}

.product-relatives-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.product-relatives-list-item {
	min-height: 80px;
}

.product-relatives-list-item-image {
	text-align: center;
	background: #f5f3f3;
	float: left;
	width: 70px;
	height: 70px;
	padding: 10px 0 0 0;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-ms-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
	-moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.product-relatives-list-item-title {
	padding: 15px 0 0 0;
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 0 85px;
}

.product-title {
	font-size: 24px;
	font-weight: bold;
	margin: 0 0 10px 0;
}

.product-parameters {
	margin: 0 0 20px 0;
}

.product-parameter-name {
	float: left;
	margin: 0 3px 0 0;
	font-weight: normal;
	color: #9f9f9f;
}

.product-parameter-value {
	min-height: 20px;
	color: #595959;
	
	a {
		color: #595959;
	}
}

.product-price {
	background: #eaf5f3;
	padding: 10px 0 10px 20px;
	height: 62px;
	margin: 0 0 25px 0;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-ms-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.product-price-item {
	float: left;
	margin: 0 35px 0 0;
}

.product-price-average {
	.product-price-value {
		font-weight: bold;
		
		.blue-big {
			font-size: 24px;
			color: #5fbcae;
		}
	}
}

.product-price-apothecoes {
	.product-price-value {
		font-size: 16px;
		color: #3b4144;
		margin: 7px 0 0 0;
	}
}

.product-price-name {
	font-size: 12px;
	color: #92a3a0;
}

.product-price-value {

}

.product-info {
	margin: 0 0 10px 0;
	
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	
		li {
			margin: 0 0 10px 0;
			text-indent: 12px;
			background: url('../img/misc/menu-bullet.png') no-repeat 0 6px;
		}
	}
}

.product-info-title {
	font-size: 16px;
	font-weight: bold;
	margin: 0 0 7px 0;
}

.product-info-value {
	margin: 0 0 20px 0;
}






