.ask-specialist {
	margin: 0 0 30px 0;
	padding: 9px 9px 9px 9px;
	background: -moz-linear-gradient(top, #f3f1f1, #f3f1f1);
	background: -webkit-linear-gradient(top, #f3f1f1, #f3f1f1);
	background: -o-linear-gradient(top, #f3f1f1, #f3f1f1);
	background: -ms-linear-gradient(top, #f3f1f1, #f3f1f1);
	background: linear-gradient(top, #f3f1f1, #f3f1f1);
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
}

.ask-specialist-form {
	padding: 9px 9px 9px 9px;
	background: #fff;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
	-o-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
	-ms-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
	
	fieldset {
		border-top: 1px solid #eae9e9;
		padding: 15px 0 0 10px;
	}
}

.ask-specialist-form-avatar {
	float: left;
	
}

.ask-specialist-form-info {
	margin: 0 0 0 117px;
	min-height: 120px;
}

.ask-specialist-form-info-name {
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 5px 0;
}

.ask-specialist-form-info-status {
	font-size: 12px;
	color: #b3b3b3;
	margin: 0 0 10px 0;
}

.ask-specialist-form-info-text {

}

.ask-specialist-form-title {
	font-size: 20px;
	margin: 0 0 10px 0;
	font-weight: bold;
}

.ask-specialist-form-input {
	width: 300px;
}

.ask-specialist-form-input-label {
	font-size: 12px;
	
	label {
		font-weight: normal;
	}
}

.ask-specialist-form-textarea-label {
	font-size: 12px;
	
	label {
		font-weight: normal;
	}
}

.ask-specialist-form-input-field {
	input {
		width: 300px;
	}
}

.ask-specialist-form-input-name {
	float: left;
}

.ask-specialist-form-input-email {
	min-height: 47px;
	margin: 0 0 0 325px;
}

.ask-specialist-form-textarea-message {
	margin: 10px 0 7px 0;
	
	.ask-specialist-form-textarea-field {
		textarea {
			width: 626px;
		}
	}
}

.ask-specialist-form-buttons {
	text-align: right;
	padding: 1px 9px 5px 0;

	input {
		border: 0;
		width: 125px;
		height: 33px;
		background: url('../img/buttons/ask-question.png') no-repeat;
	}
}













































