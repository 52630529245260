.drop-down {
	position: relative;

	input[type="text"] {
		cursor: pointer;
		background: #f4f4f2 url('../img/misc/drop-down-bullet.png') no-repeat 95% 50%;
	}
}

.drop-down-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.drop-down-list-item {
	display: none;
	
	&.active {
		display: block;
		background: url('../img/misc/fold-down-black.png') no-repeat 100% 7px;
		
		a {
			border-bottom: 1px dotted #79aed8;
		}
	}
	
	a {
		text-decoration: none;
	}
}






