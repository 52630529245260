.last-news {

}

.last-news-title {
	font-size: 16px;
	font-weight: bold;
	background: #75abd7;
	height: 33px;
	line-height: 30px;
	padding: 0 0 0 18px;
	margin: 0 0 30px 0;
	color: #fff;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-ms-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.last-news-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.last-news-list-item {
	margin: 0 0 25px 0;
	min-height: 55px;
	
}

.last-news-list-item-image {
	float: left;
}

.last-news-list-item-info {
	margin: 0 0 0 70px;
}

.last-news-list-item-info-title {
	font-size: 14px;
	margin: 0 0 5px 0;
}

.last-news-list-item-info-date {
	font-size: 12px;
	color: #bbb;
}

.last-news-all {
	height: 38px;
	padding: 0 0 0 47px;
	font-size: 12px;
	line-height: 35px;
	background: url('../img/icons/show-all-gray.png') no-repeat;
	
	&:hover {
		background: url('../img/icons/show-all-blue.png') no-repeat;
	}
	
	a {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}






