.site-footer {
  height: 140px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #a7afb4;
  background: #f4f4f2;
}

.site-footer-wrap {
  width: 980px;
  margin: 0 auto 0 auto;
  position: relative;
  padding: 30px 0 0 0;
}

.site-footer-spacer {
  height: 140px;
}

.footer-logo {
  position: absolute;
  left: 0;
  top: 35px;
}

.copyrights {
  margin: 0 0 3px 235px;
}

.copy-info {
  margin: 0 0 10px 235px;
}

.footer-menu {
  margin: 0 0 0 235px;
}

.footer-menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-menu-list-item {
  display: inline;
  white-space: nowrap;
  margin: 0 23px 0 0;

  a {
    color: #a7afb4;
  }
}




