@import "header.less";
@import "footer.less";
@import "middler.less";

html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  color: #586268;
}



a {
	text-decoration: underline;
}

form {
	input[type="text"],
	textarea {
		outline: none !important;
		background: #f4f4f2;
		box-shadow: inset 2px 2px 5px 0 #e0e0dd;
		border: 0;
		border-radius: 3px 3px 3px 3px;
		padding: 0 0 0 5px;
	}
	
	textarea {
		padding-top: 10px;
	}
	
	input[type="text"] {
		height: 33px;
		vertical-align: middle;
		
		&::-ms-clear {
			display: none;
		}
	}
	
	input[type="submit"] {
		vertical-align: middle;
	}
}

.clear {
	clear: both;
}



