.main-catalog {
	.content-3-cols-title {
		height: 48px;
		padding: 10px 0 0 60px;
		background: url('../img/icons/main-catalog.png') no-repeat;
	}
}

.main-catalog-list {
	list-style-type: none;
	margin: 0 0 25px 0;
	padding: 0;
	font-size: 14px;
}

.main-catalog-list-item {
	margin: 0 0 10px 0;
}

.main-catalog-all {
	height: 38px;
	padding: 0 0 0 47px;
	font-size: 12px;
	line-height: 35px;
	background: url('../img/icons/show-all-gray.png') no-repeat;
	
	&:hover {
		background: url('../img/icons/show-all-blue.png') no-repeat;
	}
	
	a {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}









