.main-apothecas {
	.content-3-cols-title {
		height: 48px;
		padding: 10px 0 0 60px;
		background: url('../img/icons/main-apotheca.png') no-repeat;
	}
}

.main-apothecas-list {
	list-style-type: none;
	margin: 0 0 25px 0;
	padding: 0;
}

.main-apothecas-list-item {
	margin: 0 0 10px 0;
	padding: 17px 0 17px 16px;
	border: 1px solid #f1f1f1;
	position: relative;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
}

.main-apothecas-list-item-image {
	margin: 0 0 10px 0;
}

.main-apothecas-list-item-count {
	position: absolute;
	right: 16px;
	top: 30px;
	font-size: 11px;
	color: #acaead;
	height: 12px;
	padding: 0 0 0 19px;
	background: url('../img/misc/stats.png') no-repeat 0 0;
}

.main-apothecas-list-item-search {
	input[type="text"] {
		width: 225px;
		height: 33px;
		margin: 0 7px 0 0;
	}
	
	input[type="submit"] {
		border: 0;
		background: url('../img/buttons/search.png') no-repeat;
		width: 32px;
		height: 33px;
	}
}

.main-apothecas-all {
	height: 38px;
	padding: 0 0 0 47px;
	font-size: 12px;
	line-height: 35px;
	background: url('../img/icons/show-all-gray.png') no-repeat;
	
	&:hover {
		background: url('../img/icons/show-all-blue.png') no-repeat;
	}
	
	a {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}







