.find-aid {
  padding: 10px 11px 11px 11px;
  margin: 0 0 25px 0;
  background: -moz-linear-gradient(left, #7dacb3, #6ac9bb);
  background: -webkit-linear-gradient(left, #7dacb3, #6ac9bb);
  background: -o-linear-gradient(left, #7dacb3, #6ac9bb);
  background: -ms-linear-gradient(left, #7dacb3, #6ac9bb);
  background: linear-gradient(left, #7dacb3, #6ac9bb);
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -o-border-radius: 4px 4px 4px 4px;
  -ms-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
}

.find-aid-form {
  background: #fff;
  padding: 15px 15px 15px 17px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -o-border-radius: 4px 4px 4px 4px;
  -ms-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  
  fieldset {
  
  }
}

.find-aid-form-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
  border: 0;
  color: #586268;
}

.find-aid-form-field {
  margin: 0 0 10px 0;
  
  input[type="text"] {
    width: 545px;
    margin: 0 8px 0 0;
    padding: 0 0 0 15px;
  }
  
  input[type="submit"] {
    width: 72px;
    height: 33px;
    border: 0;
    background: url('../img/buttons/find.png') no-repeat;
  }
}

.find-aid-form-subline {
  span {
    color: #b9bcbd;
  }
}









