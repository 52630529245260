.internet-apothecas {
	margin: 0 0 30px 0;
}

.internet-apothecas-title {
	font-size: 20px;
	font-weight: bold;
}

.internet-apothecas-list {
	width: 680px;
	margin: 0 0 5px 0;
}

.internet-apothecas-list-header {

}

.internet-apothecas-list-th {
	height: 33px;
	vertical-align: middle;
	text-align: left;
	background: #8fc7d7;
	font-size: 14px;
	color: #fff;
	font-weight: bold;
	padding: 0 0 0 15px;
	border-right: 1px solid #b2dae6;
}

.internet-apothecas-list-th-1 {
	width: 130px;
	-moz-border-radius: 4px 0 0 4px;
	-webkit-border-radius: 4px 0 0 4px;
	-o-border-radius: 4px 0 0 4px;
	-ms-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.internet-apothecas-list-th-2 {
	width: 270px;
}

.internet-apothecas-list-th-3 {
	width: 90px;
}

.internet-apothecas-list-th-4 {
	width: 90px;
}

.internet-apothecas-list-th-5 {
	border-right: 0;
	-moz-border-radius: 0 4px 4px 0;
	-webkit-border-radius: 0 4px 4px 0;
	-o-border-radius: 0 4px 4px 0;
	-ms-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.internet-apothecas-list-item {
	height: 80px;
	border-bottom: 1px solid #e6e6e1;
}

.internet-apothecas-list-item-image {
	vertical-align: middle;
	font-weight: bold;
	padding: 0 0 0 15px;
	
	img {
		margin: 0 0 2px 0;
	}
}

.internet-apothecas-list-item-image-subline {
	margin: 0 0 0 5px;
}

.internet-apothecas-list-item-contacts {
	vertical-align: middle;
	padding: 0 0 0 15px;
}

.internet-apothecas-list-item-contacts-site {
	margin: 0 0 2px 0;
}

.internet-apothecas-list-item-contacts-address {
	margin: 0 0 2px 0;
}

.internet-apothecas-list-item-contacts-phones {
	font-weight: bold;
}

.internet-apothecas-list-item-amount {
	font-weight: bold;
	font-size: 12px;
	vertical-align: middle;
	text-align: center;
	
	.green {
		color: #59b893;
	}
	
	.red {
		color: #fe7550;
	}
}

.internet-apothecas-list-item-price {
	font-weight: bold;
	vertical-align: middle;
	text-align: center;
	
	span {
		font-size: 18px;
	}
}

.internet-apothecas-list-item-buy {
	vertical-align: middle;
	text-align: center;
	text-align: right;
	
	a {
		display: inline-block;
		width: 72px;
		height: 33px;
		background: url('../img/buttons/buy.png') no-repeat;
		opacity: 1;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.internet-apothecas-all {
	height: 38px;
	padding: 0 0 0 47px;
	font-size: 12px;
	line-height: 35px;
	background: url('../img/icons/show-all-gray.png') no-repeat;
	
	&:hover {
		background: url('../img/icons/show-all-blue.png') no-repeat;
	}
	
	a {
		text-decoration: none;
		
		&:hover {
			text-decoration: underline;
		}
	}
}

