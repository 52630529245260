.content-services-links {
	text-align: right;
	margin: 30px 0 0 0;
	
	a {
		margin: 0 0 0 70px;
		display: inline-block;
		position: relative;
		
		&:before {
			content: ' ';
			position: absolute;
		}
	}
}

.content-services-links-compare {
	&:before {
		left: -20px;
		top: 3px;
		width: 14px;
		height: 12px;
		background: url('../img/misc/stats.png') no-repeat;
	}
}

.content-services-links-print {
	&:before {
		left: -25px;
		top: 2px;
		width: 16px;
		height: 16px;
		background: url('../img/misc/print.png') no-repeat;
	}
}








