.pagination-custom {
  margin: 25px 0 15px 0;
  text-align: center;
}

.pagination-custom-prev {
  display: inline-block;
  margin: 0 30px 0 0;
  vertical-align: middle;
}

.pagination-custom-next {
  display: inline-block;
  margin: 0 0 0 25px;
  vertical-align: middle;
}

.pagination-custom-list {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.pagination-custom-list-item {
  margin: 0 5px 0 0;
  display: inline-block;
  width: 27px;
  height: 27px;
  vertical-align: middle;
  
  a {
    color: #3b4144;
    width: 27px;
    height: 27px;
    text-align: center;
    display: inline-block;
    line-height: 26px;
    background: #f5f3f3;
    box-shadow: inset 2px 2px 5px 0 #ddd;
    border-radius: 2px 2px 2px 2px;
    text-decoration: none;
    transition: background 0.3s, color 0.3s;
    
    &:hover {
      background: #8fc7d7;
      color: #fff;
      box-shadow: none;
    }
  }
  
  &.active {
    a {
      background: #8fc7d7;
      color: #fff;
      box-shadow: none;
    }
  }
}

.pagination-custom-list-space {
  margin: 0 5px 0 0;
  display: inline-block;
  width: 10px;
  height: 27px;
  vertical-align: middle;
  line-height: 30px;
}

