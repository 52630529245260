.add-find-aid {
	padding: 8px 10px 4px 10px;
	margin: 0 0 30px 0;
	background: -moz-linear-gradient(top, #efeded, #f5f3f3);
	background: -webkit-linear-gradient(top, #efeded, #f5f3f3);
	background: -o-linear-gradient(top, #efeded, #f5f3f3);
	background: -ms-linear-gradient(top, #efeded, #f5f3f3);
	background: linear-gradient(top, #efeded, #f5f3f3);
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-o-border-radius: 5px 5px 5px 5px;
	-ms-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-moz-box-shadow: 0 0 2px 0 #ddd;
	-webkit-box-shadow: 0 0 2px 0 #ddd;
	-o-box-shadow: 0 0 2px 0 #ddd;
	-ms-box-shadow: 0 0 2px 0 #ddd;
	box-shadow: 0 0 2px 0 #ddd;
}

.add-find-aid-block {
  background: #fff;
  margin: 0 0 6px 0;
  padding: 10px 0 0 15px;
  border: 1px solid #e7e7e7;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -moz-box-shadow: 0 10px 5px -5px #ccc;
  -webkit-box-shadow: 0 10px 5px -5px #ccc;
  -o-box-shadow: 0 10px 5px -5px #ccc;
  -ms-box-shadow: 0 10px 5px -5px #ccc;
  box-shadow: 0 10px 5px -5px #ccc;
}

.add-find-aid-title {
	font-size: 18px;
	font-weight: bold;
	
}

.add-aid-small {
	padding-bottom: 15px;
	
	.add-find-aid-title {
		margin: 0 0 7px 0;
	}
}

.find-aid-small {
	padding-bottom: 10px;
	padding-right: 15px;
	
	.add-find-aid-title {
		margin: 0 0 12px 0;
	}
}

.add-aid-small-form {
	
}

.add-aid-small-form-field {
	margin: 0 0 5px 0;
	
	input {
		height: 33px;
		width: 210px;
	}
}

.add-aid-small-form-submit {
	text-align: right;
	padding-right: 18px;
	
	input {
		border: 0;
		width: 96px;
		height: 33px;
		background: url('../img/buttons/add.png') no-repeat;
		opacity: 1;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.9;
		}
	}
}

.find-aid-small-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.find-aid-small-list-item {
	font-size: 14px;
	position: relative;
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	border-bottom: 1px solid #e7e5e5;
}

.find-aid-small-list-item-remove {
	position: absolute;
	right: 0;
	top: 4px;
	width: 13px;
	height: 12px;
	outline: none;
	background: url('../img/buttons/remove.png') no-repeat;
}

.find-aid-small-clear {
	text-align: right;
	
	a {
		color: #586268;
	}
}









