.top-banner {
	img {
		width: 100%;
	}
}

.site-header {
	border-top: 1px solid #efefed;
	margin: 50px 0 0 0;
}

.site-header-wrap {
	width: 980px;
	margin: -50px auto 0 auto;
	height: 170px;
	position: relative;
	
}

.additional-menu {
	position: absolute;
	left: 0;
	top: 13px;
}

.additional-menu-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.additional-menu-list-item {
	margin: 0 0 0 12px;
	padding: 0 12px 0 0;
	display: inline-block;
  
  a {
    color: #586268;
    
    &:hover {
      text-decoration: none;
      color: #74acd7;
    }
  }
}

.socials { 
	position: absolute;
	left: 395px;
	top: 7px;
	
	img {
		vertical-align: bottom;
	}
  
  a {
    opacity: 1;
    transition: opacity 0.3s;
    
    &:hover {
      opacity: 0.9;
    }
  }
}

.sign-up {
	position: absolute;
	right: 0;
	top: 7px;
  
  a {
    color: #586268;
  }
	
	img {
		vertical-align: middle;
		margin: 0 10px 0 0;
	}
	
	span {
		color: #a7afb4;
		font-style: italic;
		font-size: 11px;
		margin: 0 10px 0 5px;
	}
}

.logo {
	position: absolute;
	left: 0;
	top: 80px;
}


.top-search {
  position: absolute;
  right: 0;
  top: 70px;
}

.top-search-form {

}

.top-search-form-city-select {
  font-size: 14px;
  font-weight: bold;
  float: left;
  width: 240px;
  padding: 30px 0 0 5px;
}

.top-search-form-city-select-label {
  color: #b9bcbd;
  float: left;
  margin: 0 5px 0 0;
}

.top-search-form-city-select-dropdown {
 
}

.top-search-form-city-select-dropdown-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top-search-form-city-select-dropdown-list-item {
  display: none;
  
  &.active {
    display: block;
  }
}

.top-search-form-word {
  float: left;
  width: 405px;
}

.top-search-form-word-types {
  height: 30px;
  margin: 0 0 6px 0;
}

.top-search-form-word-types-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top-search-form-word-types-list-item {
  display: inline-block;
  font-weight: bold;
  
  a {
    display: inline-block;
    position: relative;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    color: #586268;
    text-decoration: none;
    border-radius: 2px 2px 2px 2px;
    
    span {
      border-bottom: 1px dotted #586268;
    }
  }
  
  &.active {
    a {
      background: #73b3d2;
      color: #fff;
      cursor: default;
      
      span {
        border-bottom: 0;
      }
      
      &:before {
        content: ' ';
        position: absolute;
        width: 9px;
        height: 4px;
        bottom: -4px;
        left: 45%;
        background: url('../img/misc/tooltip-bullet.png') no-repeat;
      }
    }
  }
}

.top-search-form-word-input {
  input[type="text"] {
    width: 325px;
    margin: 0 5px 0 0;
  }
  
  input[type="submit"] {
    width: 72px;
    height: 33px;
    border: 0;
    background: url('../img/buttons/find.png') no-repeat;
  }
}

.main-menu {
	width: 980px;
	margin: 0 auto 0 auto;
  background: url('../img/main-menu-left.png') no-repeat;
}

.main-menu-wrap {
  background: url('../img/main-menu-right.png') no-repeat 100% 0;
  padding: 0 0 0 15px;
}

.main-menu-items {
  display: table;
  behavior: url('/js/display-table.min.htc'); 
  -dt-display: table;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 55px;
  width: 950px;
  background: url('../img/main-menu-middle.png') repeat-x;
}

.main-menu-item {
  display: table-cell;
  -dt-display: table-cell;
  font-size: 16px;
  font-weight: bold;
  height: 55px;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background: url('../img/main-menu-sep.png') no-repeat 100% 0;
  
  a {
    color: #586268;
    display: block;
    height: 55px;
    line-height: 38px;
    padding: 0 15px 0 15px;
    text-decoration: none;
  }
  
  &.active {
    a {
      background: url('../img/main-menu-a.png') repeat-x;
      color: #fff;
    }
  }
  
  a:hover {
    background: url('../img/main-menu-a.png') repeat-x;
    color: #fff;
  }
}

.header-banner {
	position: absolute;
	right: 0;
	top: 72px;
	width: 394px;
}
