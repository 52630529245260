.banners-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.banners-list-item {
  margin: 0 0 15px 0;
  
  img {
    transition: opacity 0.3s;
    opacity: 1;
  
    &:hover {
      opacity: 0.9;
    }
  }
}


/* 3 banners on main page */
.banners-list-row {
	margin: 0 auto 60px auto;
	width: 980px;
	
	a {
		display: inline-block;
		margin: 0 43px 0 0;
		
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}