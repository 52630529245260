.aid-search {
	height: 235px;
	margin: 20px 0 50px 0;
	background: -moz-linear-gradient(left, #74aed6, #6dcfc0);
	background: -webkit-linear-gradient(left, #74aed6, #6dcfc0);
	background: -o-linear-gradient(left, #74aed6, #6dcfc0);
	background: -ms-linear-gradient(left, #74aed6, #6dcfc0);
	background: linear-gradient(left, #74aed6, #6dcfc0);
	-moz-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
	-o-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
	-ms-box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.25);
}

.aid-search-wrap {
	width: 980px;
	margin: 0 auto 0 auto;
	height: 235px;
	padding: 37px 0 0 0;
	position: relative;
}

.aid-search-tabs {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.aid-search-tab {
	display: inline-block;
	height: 40px;
	font-size: 16px;
	font-weight: bold;
	
	&.active {		
		a {
			position: relative;
			bottom: -1px;
			background: #fff;
			border-bottom: 0 ;
			color: #596267;
			-moz-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
			-o-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
			-ms-box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
			box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
		}
	}
	
	a {
		display: inline-block;
		height: 40px;
		padding: 0 16px 0 14px;
		line-height: 35px;
		color: #fff;
		text-decoration: none;
		outline: none;
		background: url('../img/search-tabs-separator.png') no-repeat 100% 11px;
		-moz-border-radius: 3px 3px 0 0;
		-webkit-border-radius: 3px 3px 0 0;
		-o-border-radius: 3px 3px 0 0;
		-ms-border-radius: 3px 3px 0 0;
		border-radius: 3px 3px 0 0;
		
		span {
			border-bottom: 1px dotted #fff;
		}
		
		&:hover {
			span {
				border-bottom: 0;
			}
		}
	}
	
	&:last-child {
		a {
			background: transparent;
		}
	}
}

.aid-search-form {
	background: #fff;
	padding: 30px 0 25px 20px;
	-moz-border-radius: 0 0 3px 3px;
	-webkit-border-radius: 0 0 3px 3px;
	-o-border-radius: 0 0 3px 3px;
	-ms-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	-o-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	-ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.aid-search-form-city {
	float: left;
	padding: 12px 0 0 0;
	font-weight: bold;
}

.aid-search-form-city-label {
	float: left;
	color: #b9bdbc;
}

.aid-search-form-city-field {
	width: 100px;
	margin: 0 0 0 75px;
	
	.drop-down {
		
	}
}

.aid-search-form-word { 
	margin: 0 0 0 205px;
	
	input[type="text"] {
		padding: 0 0 0 20px;
		height: 40px;
		margin: 0 18px 0 0;
		width: 580px;
	}
	
	input[type="submit"] {
		border: 0;
		width: 117px;
		height: 40px;
		cursor: pointer;
		background: url('../img/buttons/search-zoom.png') no-repeat;
	}
}

.aid-search-form-word-notice {
	margin: 10px 0 0 0;
	font-size: 14px;
	color: #5b6064;
	
	span {
		color: #babbbd;
	}
}

.aid-search-extended {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -30px;
	text-align: center;
	
	a {
		display: inline-block;
		padding: 0 15px 0 15px;
		color: #fff;
		text-decoration: none;
		height: 30px;
		line-height: 25px;
		background: #70becb;
		-moz-border-radius: 0 0 5px 5px;
		-webkit-border-radius: 0 0 5px 5px;
		-o-border-radius: 0 0 5px 5px;
		-ms-border-radius: 0 0 5px 5px;
		border-radius: 0 0 5px 5px;
		
		&:hover {
			color: #eee;
		}
	}
}




