.responses-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.responses-list-item {
  padding: 15px 15px 15px 20px;
  border: 1px dashed #c9d7d8;
  margin: 0 0 15px 0;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

.responses-list-item-date-and-name {
  font-size: 12px;
  color: #b9bcbd;
  margin: 0 0 2px 0;
  
  .date {
    padding: 0 12px 0 0;
    display: inline-block;
    border-right: 1px solid #e8e8e5;
    margin: 0 12px 0 0;
  }
}

.responses-list-item-text {
  margin: 0;
  padding: 0;
  font-style: italic;
}